define('carlock-admin/services/access', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        session: Ember['default'].inject.service('session'),
        roleId: Ember['default'].computed.alias('session.session.content.authenticated.role'),
        permissions: Ember['default'].computed.alias('session.session.content.authenticated.permissions'),
        distributorUuid: Ember['default'].computed.alias('session.session.content.authenticated.distributor_uuid'),

        isAdmin: function isAdmin() {
            return this.get('roleId') === 'ADMIN';
        },
        isDistributor: function isDistributor() {
            return this.get('distributorUuid');
        },
        hasPermission: function hasPermission(required) {
            return this.get('permissions').includes(required);
        },
        isPromoDistributor: function isPromoDistributor() {
            return this.get('distributorUuid') === '8d30c379-25f1-4af8-a84d-5120db576265';
        },

        // VEHICLE
        hasVehicleDistributorOrRoAccess: function hasVehicleDistributorOrRoAccess() {
            return this.isDistributor() || this.hasVehicleRoAccess();
        },
        hasVehicleRoAccess: function hasVehicleRoAccess() {
            return this.hasPermission('VEHICLE_RO') || this.hasVehicleRwAccess();
        },
        hasVehicleRwAccess: function hasVehicleRwAccess() {
            return this.isAdmin() || this.hasPermission('VEHICLE_RW');
        },
        hasVehicleRwsAccess: function hasVehicleRwsAccess() {
            return this.isAdmin() || this.hasPermission('VEHICLE_RWS');
        },
        hasVehicleDistributorOrRwAccess: function hasVehicleDistributorOrRwAccess() {
            return this.hasVehicleRwAccess() || this.isDistributor();
        },
        hasVehicleRwOrPromoAccess: function hasVehicleRwOrPromoAccess() {
            return this.hasVehicleRwAccess() || this.isPromoDistributor();
        },
        hasPromoVehicleAccess: function hasPromoVehicleAccess() {
            return this.isAdmin() || this.isPromoDistributor() || this.hasVehicleRoAccess();
        },
        hasVehicleSpecialAccess: function hasVehicleSpecialAccess() {
            return this.isAdmin() || this.hasPermission('VEHICLE_RWS');
        },

        hasVehicleSimRwAccess: function hasVehicleSimRwAccess() {
            return this.isAdmin() || this.hasPermission('VEHICLE_SIM_RW');
        },

        hasUserRoAccess: function hasUserRoAccess() {
            return this.hasPermission('USER_RO') || this.hasUserRwAccess();
        },
        hasUserRwAccess: function hasUserRwAccess() {
            return this.isAdmin() || this.hasPermission('USER_RW');
        },

        //PURCHASE
        hasPurchaseRoAccess: function hasPurchaseRoAccess() {
            return this.hasPermission('PURCHASE_RO') || this.hasPurchaseRwAccess();
        },
        hasPurchaseDistributorOrRoAccess: function hasPurchaseDistributorOrRoAccess() {
            return this.hasPurchaseRoAccess() || this.isDistributor();
        },
        hasPurchaseRwAccess: function hasPurchaseRwAccess() {
            return this.hasPermission('PURCHASE_RW') || this.hasPurchaseRwSpecialAccess();
        },
        hasPurchaseDistributorOrRwAccess: function hasPurchaseDistributorOrRwAccess() {
            return this.hasPurchaseRwAccess() || this.isDistributor();
        },
        hasPurchaseRwSpecialAccess: function hasPurchaseRwSpecialAccess() {
            return this.isAdmin() || this.hasPermission('PURCHASE_RWS');
        },
        hasPromoPurchaseAccess: function hasPromoPurchaseAccess() {
            return this.isAdmin() || this.isPromoDistributor() || this.hasShippingPermission();
        },

        hasDistributorRoAccess: function hasDistributorRoAccess() {
            return this.isAdmin() || this.hasPermission('DISTRIBUTOR_RO');
        },

        hasCouponRoAccess: function hasCouponRoAccess() {
            return this.hasPermission('COUPON_RO') || this.hasCouponRwAccess();
        },
        hasCouponRwAccess: function hasCouponRwAccess() {
            return this.isAdmin() || this.hasPermission('COUPON_RW');
        },

        hasShippingPermission: function hasShippingPermission() {
            return this.isAdmin() || this.hasPermission('SHIPPING');
        },

        hasAnalyticsExportAccess: function hasAnalyticsExportAccess() {
            return this.isAdmin() || this.hasPermission('ANALYTICS_EXPORT');
        }
    });

});