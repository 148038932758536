/* jshint ignore:start */

define('carlock-admin/config/environment', ['ember'], function(Ember) {
  return { 'default': {"modulePrefix":"carlock-admin","environment":"staging","baseURL":"/","locationType":"auto","EmberENV":{"FEATURES":{}},"i18n":{"defaultLocale":"en"},"ember-simple-auth":{"authenticationRoute":"c.login","routeAfterAuthentication":"c.profile","routeIfAlreadyAuthenticated":"c.profile","cookieName":"session"},"APP":{"type":"WEB_ADMIN","version":"1.71.1-SNAPSHOT","name":"carlock-admin"},"hostURL":"https://testmanage.carlock.co","apiURL":"https://staging.carlock.co/v2","adminURL":"https://testadmin.carlock.co/v1","storeURL":"https://teststore.carlock.co/v1","myCarLockURL":"https://testmy.carlock.co","cdnURL":"//s3-eu-west-1.amazonaws.com/test.manage.carlock.co","legacyApiURL":"https://staging.carlock.co","managerV2URL":"https://testmanage-v2.carlock.co","development":false,"staging":true,"production":false,"something":"test","exportApplicationGlobal":true,"browserify":{"tests":true}}};
});

if (runningTests) {
  require("carlock-admin/tests/test-helper");
} else {
  require("carlock-admin/app")["default"].create({"type":"WEB_ADMIN","version":"1.71.1-SNAPSHOT","name":"carlock-admin"});
}

/* jshint ignore:end */
